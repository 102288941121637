import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-50 border-t border-gray-200">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">CheckMyCharts</h3>
            <p className="text-gray-600 mb-4">
              Helping patients understand their medical care and connect with the right professionals.
            </p>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Legal Information</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/terms" className="text-blue-600 hover:text-blue-800">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-blue-600 hover:text-blue-800">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-200">
          <p className="text-center text-gray-600 text-sm">
            © {new Date().getFullYear()} Afterimage LLC dba CheckMyCharts. All rights reserved.
          </p>
          <p className="text-center text-gray-500 text-xs mt-2">
            Disclaimer: The hiring of a lawyer or medical professional is an important decision that should not be based 
            solely upon advertisements. Material contained on CheckMyCharts.com is for general information purposes only 
            and does not constitute legal or medical advice.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
