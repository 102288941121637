import React, { useState, useEffect } from 'react';
import { AlertTriangle, FileText, MapPin, Clock } from 'lucide-react';
import DateDropdownSelector from '../../../components/DateDropdownSelector';

const Alert = ({ children, variant = 'default' }) => {
  const bgColor = variant === 'destructive' ? 'bg-red-100' : 'bg-blue-100';
  const textColor = variant === 'destructive' ? 'text-red-800' : 'text-blue-800';
  
  return (
    <div className={`${bgColor} ${textColor} p-4 rounded-md mb-4`}>
      {children}
    </div>
  );
};

const Step1CaseDetails = ({ existingCaseId, onComplete, existingData }) => {
  const [formData, setFormData] = useState(existingData || {
    condition: '',
    state: '',
    incident_date: '',
  });
  
  const [validationState, setValidationState] = useState({
    error: null,
    isSubmitting: false,
    touched: {
      condition: false,
      state: false,
      incident_date: false
    },
    showErrors: {
      condition: false,
      state: false,
      incident_date: false
    }
  });

  useEffect(() => {
    if (existingData) {
      setFormData(existingData);
    }
  }, [existingData]);

  const validateField = (name, value) => {
    switch (name) {
      case 'condition':
        return value.length >= 10 ? '' : 'Please provide more detail about your condition';
      case 'state':
        return value.length >= 2 ? '' : 'Please enter a valid location';
      case 'incident_date':
        return value ? '' : 'Please select when this occurred';
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const name = e.target.name === 'incidentDate' ? 'incident_date' : e.target.name;
    const value = e.target.value;

    setFormData(prev => ({ ...prev, [name]: value }));
    
    setValidationState(prev => ({
      ...prev,
      touched: { ...prev.touched, [name]: true }
    }));
  };

  const handleBlur = (e) => {
    const name = e.target.name === 'incidentDate' ? 'incident_date' : e.target.name;
    
    setValidationState(prev => ({
      ...prev,
      showErrors: { ...prev.showErrors, [name]: true },
      error: validateField(name, formData[name])
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationState(prev => ({ 
      ...prev, 
      isSubmitting: true, 
      error: null,
      showErrors: {
        condition: true,
        state: true,
        incident_date: true
      }
    }));

    // Validate all fields
    const errors = Object.keys(formData).map(key => validateField(key, formData[key])).filter(Boolean);
    
    if (errors.length > 0) {
      setValidationState(prev => ({
        ...prev,
        isSubmitting: false,
        error: errors[0],
        touched: Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: true }), {})
      }));
      return;
    }

    try {
      await onComplete(formData);
    } catch (error) {
      setValidationState(prev => ({
        ...prev,
        error: error.message || 'Failed to save case details. Please try again.'
      }));
    } finally {
      setValidationState(prev => ({ ...prev, isSubmitting: false }));
    }
  };

  const shouldShowError = (fieldName) => {
    return validationState.showErrors[fieldName] && validateField(fieldName, formData[fieldName]);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white py-12">
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-4xl font-bold mb-6 text-gray-800 text-center">Case Details</h1>
        
        <div className="bg-white shadow-lg rounded-xl p-8 mb-8">
          <Alert>
            <div className="flex items-center">
              <AlertTriangle className="h-5 w-5 mr-2 text-blue-500" />
              <span className="font-semibold">Why these details matter</span>
            </div>
            <p className="mt-2">
              Providing accurate information helps us understand your case better and increases the chances of identifying potential medical errors.
            </p>
          </Alert>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="condition" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                <FileText className="mr-2 h-5 w-5 text-blue-500" />
                What do you think went wrong with your care?
              </label>
              <textarea
                id="condition"
                name="condition"
                value={formData.condition}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                rows="4"
                placeholder="Tell us what happened and how it affected you"
                className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200
                  ${shouldShowError('condition') ? 'border-red-500' : 'border-gray-300'}`}
              />
              {shouldShowError('condition') && (
                <p className="mt-1 text-sm text-red-600">{validateField('condition', formData.condition)}</p>
              )}
            </div>

            <div>
              <label htmlFor="state" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                <MapPin className="mr-2 h-5 w-5 text-blue-500" />
                Where did it happen?
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                placeholder="Enter the city and state"
                className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200
                  ${shouldShowError('state') ? 'border-red-500' : 'border-gray-300'}`}
              />
              {shouldShowError('state') && (
                <p className="mt-1 text-sm text-red-600">{validateField('state', formData.state)}</p>
              )}
            </div>

            <div>
              <label htmlFor="incident_date" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                <Clock className="mr-2 h-5 w-5 text-blue-500" />
                When did this happen?
              </label>
              <DateDropdownSelector
                formData={formData}
                handleChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            {validationState.error && (
              <Alert variant="destructive">
                <div className="flex items-center">
                  <AlertTriangle className="h-5 w-5 mr-2" />
                  <span className="font-semibold">Error</span>
                </div>
                <p className="mt-2">{validationState.error}</p>
              </Alert>
            )}

            <button 
              type="submit" 
              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded-full transition duration-300 flex items-center justify-center"
              disabled={validationState.isSubmitting}
            >
              {validationState.isSubmitting ? 'Submitting...' : 'Next Step'}
              <svg className="ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </form>
        </div>

        <div className="text-center text-gray-600 text-sm flex items-center justify-center">
          <svg className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg>
          Your information is secure and will only be used for case review purposes.
        </div>
      </div>
    </div>
  );
};

export default Step1CaseDetails;
