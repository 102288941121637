import React, { useEffect, useState } from 'react';
import { patientFlowAPIUnordered as api } from '../../../services/api';
import { CheckCircle, Clock, AlertTriangle } from 'lucide-react';

const CaseConfirmation = ({ caseId: propCaseId, onComplete }) => {
  const [localCaseId, setLocalCaseId] = useState(propCaseId);
  const [confirmationStatus, setConfirmationStatus] = useState('loading');

  useEffect(() => {
    if (!localCaseId) {
      const storedCaseId = localStorage.getItem('caseId');
      if (storedCaseId) {
        setLocalCaseId(storedCaseId);
      } else {
        console.error('CaseConfirmation - No caseId found in props or localStorage');
        setConfirmationStatus('error');
      }
    }
  }, [localCaseId]);

  useEffect(() => {
    const confirmCase = async () => {
      if (!localCaseId) {
        console.error('CaseConfirmation - No caseId available for confirmation');
        setConfirmationStatus('error');
        return;
      }

      try {
        // Update case with confirmed status
        await api.updateCase(localCaseId, { status: 'confirmed' });
        setConfirmationStatus('success');
        if (onComplete) onComplete();
      } catch (error) {
        console.error('CaseConfirmation - Error confirming case:', error);
        setConfirmationStatus('error');
      }
    };

    if (localCaseId) {
      confirmCase();
    }
  }, [localCaseId, onComplete]);

  const renderContent = () => {
    switch (confirmationStatus) {
      case 'loading':
        return (
          <div className="flex flex-col items-center">
            <Clock className="w-16 h-16 text-blue-500 mb-4 animate-pulse" />
            <h1 className="text-3xl font-bold mb-4 text-gray-800">Confirming Your Case</h1>
            <p className="text-xl text-gray-600">Please wait while we process your submission...</p>
          </div>
        );
      case 'success':
        return (
          <>
            <div className="flex flex-col items-center mb-8">
              <CheckCircle className="w-16 h-16 text-green-500 mb-4" />
              <h1 className="text-3xl font-bold mb-4 text-gray-800">Case Submission Confirmed</h1>
              <p className="text-xl text-gray-600">Your case (ID: {localCaseId}) is being processed!</p>
            </div>
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 mb-8">
              <h2 className="text-xl font-semibold text-gray-800 mb-3">What's Next?</h2>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Our system will review your case thoroughly.</li>
                <li>We'll get back to you with your results within 5 to 10 business days.</li>
                <li>If we need any additional information, we'll contact you promptly.</li>
              </ul>
            </div>
            <p className="text-gray-600 mb-6">We're committed to helping you gain more confidence in your healthcare situation.</p>
            <p className="font-bold text-green-500 text-2xl">Status: Processing</p>
          </>
        );
      case 'error':
        return (
          <div className="flex flex-col items-center">
            <AlertTriangle className="w-16 h-16 text-red-500 mb-4" />
            <h1 className="text-3xl font-bold mb-4 text-gray-800">Confirmation Error</h1>
            <p className="text-xl text-gray-600 mb-6">We encountered an issue while confirming your case. Please try again or contact support.</p>
            <button
              onClick={() => window.location.reload()}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
            >
              Retry Confirmation
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white py-12">
      <div className="container mx-auto px-4 max-w-3xl">
        <div className="bg-white shadow-lg rounded-xl p-8 text-center">
          {renderContent()}
        </div>
        <div className="text-center mt-8">
          <p className="text-gray-600">
            Need assistance? <a href="#" className="text-blue-500 hover:underline">Contact our support team</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export { CaseConfirmation };
