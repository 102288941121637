import React, { useState, useEffect } from 'react';

const YouTubePlayer = ({ isMobile }) => {  // Accept prop to know which version to render
  return (
    <iframe 
      className="w-full aspect-video rounded-xl shadow-lg" 
      src="https://www.youtube.com/embed/0vUuGpK3T8A"
      title="YouTube video player" 
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  );
};

export default YouTubePlayer;
