import React from 'react';
import ReactMarkdown from 'react-markdown';

const PrivacyPolicy = () => {

const privacyContent = `# CheckMyCharts Privacy Policy
*Effective Date: January 22, 2025*

## 1. Introduction

### 1.1 About Us
Thank you for visiting CheckMyCharts, operated by Afterimage LLC ("CheckMyCharts", "we", "our", or "Company"). We operate as a technology platform connecting patients with healthcare providers and legal professionals. We are not a law firm or medical practice, but rather a service that facilitates connections between patients and qualified professionals.

### 1.2 Purpose of Policy
This Privacy Policy governs your use of our website and services. It describes what information CheckMyCharts may collect about you, why we collect it, how we use it, and under what circumstances we may share it with third parties. This Privacy Policy also describes your choices regarding how we collect and use your information.

### 1.3 Consent
By using our website and services, you consent to our Privacy Policy and Terms of Service, including our collection, use, and sharing of your information as described below. You specifically authorize CheckMyCharts to disclose your information to applicable healthcare providers and law firms, including their agents and assignees.

### 1.4 Children's Privacy
We are a general audience platform and do not direct any content specifically at children under 13 years of age. If we learn that a user is under age 13, we will promptly delete any personal information related to that user. For cases involving minors, we require parent or guardian consent and involvement.

## 2. Information We Collect

### 2.1 Categories of Information
We collect the following types of information:

- Personal identification information (name, contact details, date of birth)
- Medical history and records you choose to share
- Information about your healthcare experiences and potential legal claims
- Technical information about your device and how you use our website
- Communications between you and our service

### 2.2 Collection Methods
We collect information through:
- Direct submission through our website forms
- Communications with our service team
- Automatic collection of technical data
- Cookies and similar tracking technologies

## 3. How We Use Your Information

### 3.1 Primary Uses
We use your information to:
- Connect you with appropriate healthcare providers and legal professionals
- Evaluate and respond to your requests for assistance
- Improve our services and user experience
- Communicate with you about your case or inquiry
- Comply with legal obligations

### 3.2 Information Sharing
We may share your information with:
- Healthcare providers reviewing your case (with your consent)
- Legal professionals evaluating your situation (with your consent)
- Service providers who assist our operations
- Regulatory authorities when required by law

## 4. Your Rights and Choices

### 4.1 Access and Control
You have the right to:
- Access your personal information
- Request deletion of your information
- Opt out of communications
- Update your personal information
- Request a copy of your data

### 4.2 California Privacy Rights
California residents have additional rights under the CCPA/CPRA, including:
- Right to know what personal information we collect and share
- Right to delete personal information
- Right to opt-out of certain data sharing
- Right to non-discrimination for exercising these rights

## 5. Security

We implement appropriate technical and organizational measures to protect your information. However, no internet transmission is completely secure, and we cannot guarantee the security of information transmitted through our website.

## 6. Updates to This Policy

We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date.

## 7. Contact Us

If you have questions about this Privacy Policy or our practices, please contact us at:

Afterimage LLC  
Email: privacy@checkmycharts.com  
Phone: (800) XXX-XXXX

*Last Updated: January 22, 2025*`;

  return (
    <div className="min-h-screen bg-white">
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <div className="prose max-w-none">
          <ReactMarkdown>{privacyContent}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

