import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import LandingPage from './LandingPage';
import Step1CaseDetails from './qual_first/Step1CaseDetails';
import Step2ContactInfo from './qual_first/Step2ContactInfo';
import { Step3RecordUpload as QualFirst_Step3RecordUpload } from './qual_first/Step3RecordUpload';
import { CaseConfirmation as QualFirst_CaseConfirmation } from './qual_first/CaseConfirmation';
import Step1PersonalInfo from './Step1PersonalInfo';
import Step2CaseDetails from './Step2CaseDetails';
import Step3RecordUpload from './Step3RecordUpload';
import CaseConfirmation from './CaseConfirmation';
import Results from './Results';
import Footer from './Footer';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import { patientFlowAPIUnordered as api, trackEvent } from '../../services/api';

// Standard flow: Contact -> Qualification -> Upload
const StandardFlow = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [caseId, setCaseId] = useState(null);
  const [referrer, setReferrer] = useState(null);

  useEffect(() => {
    const storedCaseId = localStorage.getItem('caseId');
    if (storedCaseId) {
      setCaseId(storedCaseId);
    }
    
    // Get and store referrer from URL params
    const ref = searchParams.get('ref');
    if (ref) {
      setReferrer(ref);
      localStorage.setItem('referrer', ref);
    }
  }, [searchParams]);

  useEffect(() => {
    if (caseId) {
      localStorage.setItem('caseId', caseId);
    }
  }, [caseId]);

  const handleStep1Complete = (newCaseId) => {
    setCaseId(newCaseId);
    navigate('/standard/step2');
  };

  const handleStep2Complete = () => {
    navigate('/standard/step3');
  };

  const handleStep3Complete = () => {
    navigate('/standard/confirmation');
  };

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/step1" element={<Step1PersonalInfo onComplete={handleStep1Complete} referrer={referrer} />} />
      <Route path="/step2" element={<Step2CaseDetails caseId={caseId} onComplete={handleStep2Complete} />} />
      <Route path="/step3" element={<Step3RecordUpload caseId={caseId} onComplete={handleStep3Complete} />} />
      <Route path="/confirmation" element={<CaseConfirmation caseId={caseId} />} />
      <Route path="/results" element={<Results caseId={caseId} />} />
    </Routes>
  );
};

// Alternative flow: Qualification -> Contact -> Upload
const QualificationFirstFlow = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [caseId, setCaseId] = useState(null);
  const [referrer, setReferrer] = useState(null);

  useEffect(() => {
    const storedCaseId = localStorage.getItem('caseId');
    if (storedCaseId) {
      setCaseId(storedCaseId);
    }
    
    const ref = searchParams.get('ref');
    if (ref) {
      setReferrer(ref);
      localStorage.setItem('referrer', ref);
    }
  }, [searchParams]);

  useEffect(() => {
    if (caseId) {
      localStorage.setItem('caseId', caseId);
    }
  }, [caseId]);

  // Create case in first step only
  const handleQualificationComplete = async (qualificationInfo) => {
    try {
      const newCase = await api.initializeCase(qualificationInfo);
      setCaseId(newCase.id);
      navigate('/qual-first/contact');
    } catch (error) {
      console.error('Error in qualification step:', error);
    }
  };

  // Update existing case in second step
  const handleContactComplete = async (personalInfo) => {
    try {
      if (!caseId) {
        throw new Error('No case ID found');
      }
      await api.updateCase(caseId, personalInfo);
      navigate('/qual-first/confirmation');
    } catch (error) {
      console.error('Error in contact completion:', error);
    }
  };

  const handleUploadComplete = () => {
    navigate('/qual-first/confirmation');
  };

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route 
        path="/step1" 
        element={
          <Step1CaseDetails 
            onComplete={handleQualificationComplete}
            existingCaseId={caseId} 
          />
        }
      />
      <Route 
        path="/contact" 
        element={
          <Step2ContactInfo 
            onComplete={handleContactComplete}
            existingCaseId={caseId}
          />
        }
      />
      <Route 
        path="/upload" 
        element={
          <QualFirst_Step3RecordUpload 
            existingCaseId={caseId} 
            onComplete={handleUploadComplete} 
          />
        }
      />
      <Route 
        path="/confirmation" 
        element={
          <QualFirst_CaseConfirmation 
            existingCaseId={caseId} 
          />
        }
      />
      <Route 
        path="/results" 
        element={
          <Results 
            existingCaseId={caseId} 
          />
        }
      />
    </Routes>
  );
};

// Main router that decides which flow to use
const PatientFlow = () => {
  const location = useLocation();
  const isQualificationFirst = location.pathname.startsWith('/qual-first');

  return (
    <>
    <Routes>
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/standard/*" element={<StandardFlow />} />
      <Route path="/qual-first/*" element={<QualificationFirstFlow />} />
      <Route path="*" element={<StandardFlow />} />
    </Routes>
    <Footer />
    </>
  );
};

export default PatientFlow;
