import React, { useState } from 'react';
import { Phone } from 'lucide-react';
import { patientFlowAPIUnordered as api, trackEvent } from '../../../services/api';

// Custom Alert component
const Alert = ({ children, variant = 'default' }) => {
  const bgColor = variant === 'destructive' ? 'bg-red-100' : 'bg-blue-100';
  const textColor = variant === 'destructive' ? 'text-red-800' : 'text-blue-800';
  
  return (
    <div className={`${bgColor} ${textColor} p-4 rounded-md mb-4`}>
      {children}
    </div>
  );
};

const Step2ContactInfo = ({ existingCaseId, onComplete }) => {
  const [formData, setFormData] = useState({ 
    name: '', 
    email: '',
    phone: '' 
  });
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validatePhone = (phone) => {
    const phoneRegex = /^\D*(\d{3})\D*(\d{3})\D*(\d{4})\D*$/;
    return phoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'phone') {
      // Remove all non-digits
      const digitsOnly = value.replace(/\D/g, '');
      // Format as (XXX) XXX-XXXX if we have enough digits
      let formattedValue = value;
      if (digitsOnly.length >= 10) {
        formattedValue = `(${digitsOnly.slice(0,3)}) ${digitsOnly.slice(3,6)}-${digitsOnly.slice(6,10)}`;
      }
      setFormData({ ...formData, [name]: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
    
    // Validate phone number
    if (!validatePhone(formData.phone)) {
      setError('Please enter a valid phone number');
      setIsSubmitting(false);
      return;
    }

    trackEvent('Lead', 'qual-first/step2')
      .catch(error => console.error('Error tracking Lead:', error));

    try {
      if (!existingCaseId) {
        throw new Error('No case ID provided');
      }

      // Always update the existing case
      await api.updateCase(existingCaseId, {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        status: 'pending'
      });

      onComplete(formData);
    } catch (error) {
      console.error('Error saving personal info:', error);
      setError(error.message || 'Failed to save personal information. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
 
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white py-12">
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-4xl font-bold mb-6 text-gray-800 text-center">Step 2: Contact Information</h1>
        <div className="bg-white shadow-lg rounded-xl p-8 mb-8">
          <Alert>
            <div className="flex items-center">
              <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="font-semibold">Did you know?</span>
            </div>
            <p className="mt-2">
              1 in 20 adults in the US experience a diagnostic error every year. We'll help you find out if you were affected.
            </p>
          </Alert>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-gray-700 text-sm font-semibold mb-2">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your full name"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email address"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                <Phone className="mr-2 h-5 w-5 text-blue-500" />
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="(555) 555-5555"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              />
            </div>
            {error && (
              <Alert variant="destructive">
                <div className="flex items-center">
                  <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span className="font-semibold">Error</span>
                </div>
                <p className="mt-2">{error}</p>
              </Alert>
            )}
            <button 
              type="submit" 
              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded-full transition duration-300 ease-in-out flex items-center justify-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Next Step'}
              <svg className="ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </form>
        </div>
        <div className="text-center text-gray-600 text-sm flex items-center justify-center">
          <svg className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg>
          Your information is secure and will only be used for case review purposes.
        </div>
      </div>
    </div>
  );
};

export default Step2ContactInfo;
