import React from 'react';
import ReactMarkdown from 'react-markdown';

const TermsOfService = () => {

const termsContent = `# CheckMyCharts Terms of Service
*Including Binding Arbitration Agreement and Class Action Waiver*

## 1. Introduction

Welcome to CheckMyCharts ("Platform"), operated by Afterimage LLC ("Company", "we", "us", "our"). These Terms of Service ("Terms") govern your use of our website and services. 

IMPORTANT NOTICE: These Terms contain a Binding Arbitration provision requiring disputes to be arbitrated on an individual basis. By accessing our website, you agree to these terms.

## 2. Nature of Services

### 2.1 Not Medical or Legal Advice
CheckMyCharts is a technology platform that connects patients with healthcare providers and legal professionals. We are not a medical practice or law firm. We do not provide medical advice, legal counsel, or professional services directly.

### 2.2 Role of Platform
We facilitate connections between:
- Patients seeking review of their medical cases
- Licensed healthcare providers
- Qualified legal professionals

The professional services you may receive will be provided directly by these third-party professionals, not by CheckMyCharts.

## 3. User Agreements

### 3.1 Eligibility
You must be at least 18 years old and legally capable of entering into binding contracts to use our services.

### 3.2 Account Creation
You agree to provide accurate, current, and complete information during registration and to update such information to keep it accurate.

## 4. Privacy and Data Protection

### 4.1 Privacy Policy
Our Privacy Policy, incorporated by reference, explains how we collect, use, and protect your information.

### 4.2 Medical Information
While we implement appropriate safeguards, our platform is not covered by HIPAA. Your direct relationships with healthcare providers will be covered by their HIPAA policies.

## 5. Professional Services

### 5.1 Third-Party Providers
Healthcare providers and legal professionals accessible through our platform are independent contractors, not employees or agents of CheckMyCharts.

### 5.2 No Guarantee
We do not guarantee:
- The availability of any professional
- The outcome of any consultation or case
- The quality of professional services
- The accuracy of any provider's credentials or information

## 6. User Conduct

### 6.1 Prohibited Activities
You agree not to:
- Submit false or misleading information
- Violate any applicable laws or regulations
- Interfere with platform operations
- Attempt unauthorized access
- Harass or threaten others

## 7. Intellectual Property

### 7.1 Ownership
All platform content and features are owned by CheckMyCharts or our licensors and are protected by copyright and other intellectual property laws.

### 7.2 Limited License
We grant you a limited, non-exclusive license to access our platform for personal use.

## 8. Disclaimer of Warranties

THE PLATFORM IS PROVIDED "AS IS" WITHOUT ANY WARRANTIES, EXPRESS OR IMPLIED. WE DO NOT WARRANT THAT THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE.

## 9. Limitation of Liability

TO THE MAXIMUM EXTENT PERMITTED BY LAW, CHECKMYCHARTS SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES.

## 10. Binding Arbitration Agreement

### 10.1 Agreement to Arbitrate
You agree that any dispute between you and CheckMyCharts will be resolved by binding arbitration, rather than court.

### 10.2 Class Action Waiver
YOU WAIVE ANY RIGHT TO PARTICIPATE IN CLASS ACTIONS OR CLASS-WIDE ARBITRATION.

### 10.3 Arbitration Procedures
- Arbitration will be conducted by the American Arbitration Association
- Proceedings will be held in San Francisco, California
- The arbitrator's decision will be binding
- Each party bears its own costs

## 11. Governing Law

These Terms are governed by the laws of the State of California, without regard to conflict of law principles.

## 12. Changes to Terms

We may modify these Terms at any time. Continued use of the platform constitutes acceptance of modified Terms.

*Last Updated: January 22, 2025*`;

return (
    <div className="min-h-screen bg-white">
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <div className="prose max-w-none">
          <ReactMarkdown>{termsContent}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;

